import { Link as GatsbyLink } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import slugify from "slugify";

/* eslint-disable */

const SLUGIFY_OPTIONS = {
  replacement: "-", // replace spaces with replacement
  remove: /[^A-Za-z\-\_0-9 ]/, // regex to remove characters
  lower: true, // result in lower case
};
/* eslint-enable */

export const getPublicationLink = (title, locale) => {
  const slug = slugify(title, SLUGIFY_OPTIONS);

  return getLocalizedLink(`/publikationen/${slug}`, locale);
};

export const getArticleOrPublicationLink = (title, type, locale) => {
  if (type === "ContentfulPublicationDetail") {
    return getPublicationLink(title, locale);
  } else {
    return getArticleLink(title, locale);
  }
};

export const getArticleLink = (title, locale) => {
  const slug = slugify(title, SLUGIFY_OPTIONS);

  return getLocalizedLink(`/artikel/${slug}`, locale);
};

export const getTeamMemberLink = (title, locale) => {
  const slug = slugify(title, SLUGIFY_OPTIONS);

  return getLocalizedLink(`/team/${slug}`, locale);
};

export const getJobLink = (title, locale) => {
  const slug = slugify(title, SLUGIFY_OPTIONS);

  return getLocalizedLink(`/jobs/${slug}`, locale);
};

export const getFilteredSearchLink = (title, locale) => {
  return getLocalizedLink(`/search?tag=${title}`, locale);
};

export const getLocalizedLink = (link, locale) => {
  return locale !== "de-DE" ? `/${locale}${link}` : link;
};

const Link = ({ to, className, locale, children, ...props}) => {
  if (to === '/home') {
    to = '/'
  }
  return to.url ? (
    <a
      href={to.url}
      className={className}
      target="_blank"
      rel="noopener noreferrer"
      {...props}
    >
      {to.linkText}
      {children}
    </a>
  ) : (
    <GatsbyLink to={to} className={className} rel="noopener noreferrer" {...props}>
      {children}
    </GatsbyLink>
  );
}

Link.propTypes = {
  to: PropTypes.oneOfType([
    PropTypes.shape({
      url: PropTypes.string.isRequired,
      linkText: PropTypes.string.isRequired,
    }),
    PropTypes.string,
  ]),
  className: PropTypes.string,
};

export default Link;
